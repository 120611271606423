import React from "react"
import "../scss/main.scss"
import { Helmet } from "react-helmet"
import logo from "../img/sg-logo.svg"
import icon from "../img/icon-48x48.png"
import card from "../img/card.png"

const Layout = ({ title, description, url, children }) => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="og:image" content={card} />
        <meta property="twitter:image" content={card} />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="theme-color" content="#fef5f8" />
        <link rel="canonical" href={url} />
        <link rel="shortcut icon" href={icon} />
      </Helmet>
      <header className="py-3 bg-white shadow-sm">
        <div className="container-fluid">
          <div className="d-flex">
            <a href="/" className="navbar-brand line-height-1">
              <img src={logo} height="32"/>
            </a>
            {/* <ul className="nav ml-auto">
              <li className="nav-item">
                <a className="nav-link text-body active" href="#">What?</a>
              </li>
            </ul> */}
          </div>
        </div>
      </header>
      {children}
      <footer className="bg-dark py-3 text-light">
        <div className="container">
          <div className="d-flex justify-content-center text-muted">
            <span>stroll guide forever</span>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout;